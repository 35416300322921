import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import './App.css';
import Paperbase from './Paperbase';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router basename='/ui'>
        <div className="App">
          <Paperbase />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
