import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    partialBundledLanguages: true,
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'],
    },
    // lng: 'fr',

    fallbackLng: "en",
    debug: false,

    resources: {
      en: {
        translation: {
          // Header
          "dashboardTitle": "Ontoserver Dashboard",
          "goToDocs": "Go to docs",
          "alerts": "Alerts • No alerts",
          "help": "Help",
          // Nav
          "Login": "Login",
          "Logout": "Logout",
          "Admin": "Admin",
          "About": "About",
          "Resources": "Resources",
          "Syndication": "Syndication",
          "UploadSNOMED": "Upload SNOMED",
          "Apps": "Applications",
          "Shrimp": "Shrimp",
          "Snapper": "Snapper",
          "Account": "Account",

          "Copyright": "Copyright",

          "Loading": "Loading",
          "Failed to load": "Failed to load",
          "Endpoint": "Endpoint",
          "Contact_one": "Contact",
          "Contact_other": "Contacts",
          "Software": "Software",
          "Name": "Name",
          "Version": "Version",
          "Release Date": "Release Date",
          "Supported Formats": "Supported Formats",
          "Details": "Details",
          
          "Cancel": "Cancel",
          "Ok": "OK",

          "Id": "Id",
          "Title": "Title",
          "URL": "URL",
          "Status": "Status",
          "Domain": "Domain",
          "Last Updated": "Last Updated",
          "Publisher": "Publisher",
          "Description": "Description",
          "Purpose": "Purpose",
          "Tags": "Tags",
          "Synd": "Synd",
          "Request": "Request",
          "Result": "Result",
          "Updated": "Updated",

          "AuthRequired": "Access to {{type}} resources requires authorisation.",
          "SearchFailure": "Something went wrong retrieving the {{type}} resources. They may not be supported by this server.",
          "Delete Resource": "Delete Resource",
          "Really delete Resource": "Really delete Resource",

          "History": "History",
          "Validation": "Validation",
          "Changes": "Changes",
          "Narrative": "Narrative",
          "Next Page": "Next Page",
          "Previous Page": "Previous Page",
          "First Page": "First Page",
          "Last Page": "Last Page",
          "rows label": "rows",
          "rows range": "{from}-{to} of {count}",
          "GroupPlaceholder": "Drag headers here to group by",
          "GroupBy": "Grouped by:",
          "Search": "Search",
          "Actions": "Actions",

          "Error": "Error",

          "Toggle Syndication": "Toggle Syndication",

          "Module": "Module",
          "File Type": "File Type",
          "Experimental version": "Experimental version",
          "SelectRF2": "Select RF2 file(s)",
          "SelectRF2Prompt": "Please select at least one RF2 zip file.",
          "doIndex": "Upload & Index",
          "doingIndexing": "Processing...",

          "Upload Resource": "Upload {{type}} Resource",
          "The resource to upload.": "The resource to upload.",
          "SelectJSON": "Select {{type}} JSON file",
          "SelectJSONPrompt": "Please select a FHIR {{type}} JSON file.",

          "SyndAuthRequired": "Authorisation is required to access the upstream feed.",
          "GenericAuthRequired": "Authorisation is required for this action.",
          "SyndFetchFailure": "Something went wrong retrieving upstream feed.",
          "Redo preloading": "Redo preloading",
          "Preload": "Preload",
          "Retrieving feed": "Retrieving feed...",
          "Available Resources": "Available Resources",
          "Category": "Category",
          "Scheme": "Scheme",
          "Source": "Source",

          // "MaterialTableDragTarget": "Drag headers here to group by",

          "Opreplace": "Replace",
          "Opdelete": "Delete",
          "Opinsert": "Insert",
          "Opadd": "Add",
          "Operation": "Operation",
          "Index": "Index",
          "Value": "Value",
          "PrevValue": "Previous",
          "Path": "Path",

          // "": "",
          "Ontoserver": "Ontoserver"

        }
      }
    },
    backend: {
      // Other languages here
      loadPath: '/ui/locales/{{lng}}.json',
    },

    interpolation: {
      escapeValue: false // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

  export default i18n;
  
