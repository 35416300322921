import React from "react"
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Error = () => {
    const query = useQuery();
    const message = query.get('message') || 'Unknown error';

    return (
        <div>
            <h2>Error</h2>
            <p>
                {message}
            </p>
        </div>
    )
}

export default Error;

