
import React from 'react';
import { CircularProgress, Badge, Tooltip } from '@material-ui/core';
import SmartContext from './SmartContext';
import { ThumbUp, NotInterested, MoreHoriz } from '@material-ui/icons';
import { green, blue, red } from '@material-ui/core/colors';

interface ResourceHistoryProps {
  category: string;
  url: string;
  version: string;
}

const categoryTypes: any = {
  "FHIR_Bundle": "Bundle",
  "FHIR_Package": "Bundle",
  "FHIR_ValueSet": "ValueSet",
  "FHIR_CodeSystem": "CodeSystem",
  "BINARY": "CodeSystem",
  "LOINC": "CodeSystem",
  "FHIR_ConceptMap": "ConceptMap",
  "FHIR_NamingSystem": "NamingSystem",
  "FHIR_StructureDefinition": "StructureDefinition",
  "SCT_RF2_ALL": "CodeSystem",
  "SCT_RF2_FULL": "CodeSystem",
  "SCT_RF2_SNAPSHOT": "CodeSystem",

}

const notInstallable = (<Tooltip title="Content type not loadable"><NotInterested aria-label="not installable" style={{ color: red[500] }} /></Tooltip>);
const present = (<Tooltip title="Present on server"><ThumbUp aria-label="installed" style={{ color: green[500] }} /></Tooltip>);
const notPresent = (<Tooltip title="Not present on server"><MoreHoriz aria-label="" style={{ color: blue[500] }} /></Tooltip>);

export const ResourceExists = (props: ResourceHistoryProps) => {
  const { category, url, version } = props;

  const resourceType = categoryTypes[category];
  const [loading, setLoading] = React.useState(!!resourceType)
  const [exists, setExists] = React.useState(0)
  const smart = React.useContext(SmartContext);
  const fhirClient = smart.client

  React.useEffect(() => {
    let mounted = true;
    if (resourceType) {
      if ('Bundle' === resourceType) {
        if (mounted) {
          setLoading(false);
        }
      } else {
        fhirClient?.request(`${resourceType}?url=${url}&version=${version}&_elements=id`)
          .then(bundle => {
            if (mounted) {
              setExists(bundle.entry?.length || 0)
            }
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          })
      }
    }
    return () => { mounted = false }
  }, [fhirClient, url, version, resourceType]);

  return loading ? (<CircularProgress />) :
    (
      <React.Fragment>
        {!resourceType ? notInstallable : (
          exists
            ? (exists > 1 ? (<Badge color='secondary' badgeContent={exists} max={9}>{present}</Badge>) : present)
            : notPresent
        )}
      </React.Fragment>
    )
}
