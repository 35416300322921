import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Apps, CloudUpload, Cloud, Home, LibraryBooks, RssFeed, AccountBox, Visibility, Edit } from '@material-ui/icons';
import { Omit } from '@material-ui/types';
import { NavLink, useLocation } from 'react-router-dom';
import SmartContext from './SmartContext';
import Branding from './Branding';
import { withTranslation, WithTranslation } from 'react-i18next';

const features = {
  upload: true,
  syndication: true,
  apps: false,
}

type MenuType = {
  id: string;
  disable?: boolean;
  icon?: any;
  active?: boolean;
  href?: string;
  target?: string;
}

const categories: { id: string, title?: string, disable?: boolean, children: MenuType[] }[] = [
  {
    id: 'Admin',
    title: 'Admin',
    children: [
      { id: 'About', icon: <Home />, href: '/about' },
      { id: 'Resources', icon: <LibraryBooks />, href: '/resource', active: true },
      { id: 'Syndication', icon: <RssFeed />, href: '/upstream', disable: !features.syndication },
      { id: 'UploadSNOMED', icon: <CloudUpload />, href: '/upload-sct', disable: !features.upload },
      // { id: 'Syndication (local)', icon: <RssFeed />, href: '/syndication', disable: !features.syndication },

      // { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
      // { id: 'Functions', icon: <SettingsEthernetIcon /> },
      // { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
      // { id: 'Test', href: '/test' },
      { id: 'Applications', icon: <Apps />, href: '/applications', disable: true },
    ],
  },
  {
    id: 'Apps',
    title: 'Apps',
    disable: !features.apps,
    children: [
      { id: 'Shrimp', href: '/shrimp/app', icon: <Visibility /> },
      { id: 'Snapper', href: '/snapper/app', icon: <Edit /> },
    ],
  },
];

if (process.env.NODE_ENV === 'development') {
  categories.unshift({
    id: 'Endpoint',
    children: [
      { id: 'Launch', icon: <Cloud />, href: '/login' },
    ],
  })
}

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    categoryBlank: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });


export interface NavigatorProps extends WithTranslation, Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {
  defaultUrl?: string;
}

const shrimpUrl = process.env.REACT_APP_SHRIMP ?? 'https://ontoserver.csiro.au/shrimp/launch.html';
const snapperUrl = process.env.REACT_APP_SNAPPER ?? '/snapper/';	// Default assumes AdminUI and Snapper are deployed as siblings

const shrimp =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost/~law223/shrimp-src/launch.html'
    : shrimpUrl;
const snapper =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost/~law223/snapper2-dev/'
    : snapperUrl;

function Navigator(props: NavigatorProps) {
  const { defaultUrl, classes, t, ...other } = props;

  const smart = React.useContext(SmartContext);
  const account_endpoint = smart.onto?.account_endpoint;
  const fhirClient = smart.client
  const [special, setSpecial] = React.useState(false)
  
  React.useEffect(() => {
    const clientUrl = fhirClient?.state?.serverUrl;
    // Only enable for local testing or "ontoserver-based" URLs
    setSpecial(!(clientUrl && clientUrl.indexOf('ontoserver') < 0 && clientUrl.indexOf('localhost') < 0))
  }, [fhirClient])
  
  React.useEffect(() => {
    categories.forEach(cat => {
      cat.children.forEach(child => {
        if (child.href === '/upload-sct') {
          child.disable = child.disable || !smart.onto?.canUpload;
        }
      })
    })
  }, [smart.onto])

  const location = useLocation();

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding style={{ height: '99%' }}>
        <ListItem>
          <Branding />
        </ListItem>
        <li><Divider className={classes.divider} /></li>
        {categories.filter(cat => !cat?.disable).map(({ id, title, children }) => (
          <React.Fragment key={id}>
            {title ? <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {t(title)}
              </ListItemText>
            </ListItem> : <li><div className={classes.categoryBlank} /></li>}
            {children.filter(cat => !cat?.disable).map(({ id: childId, href = '/', icon, target }) => (
              <li key={childId}>
                <ListItem
                  component={NavLink} to={href} target={target} hidden={'Syndication' === childId && !special}
                  aria-current='location'
                  button
                  className={clsx(classes.item, (href === location.pathname) && classes.itemActiveItem)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {t(childId)}
                  </ListItemText>
                </ListItem>
              </li>
            ))}
            <li><Divider className={classes.divider} /></li>
          </React.Fragment>
        ))}
        <ListItem className={classes.categoryHeader}>
          <ListItemText classes={{ primary: classes.categoryHeaderPrimary }}>
            {t('Apps')}
          </ListItemText>
        </ListItem>
        {account_endpoint &&
          <li>
            <ListItem
              component="a" href={account_endpoint} target="_blank"
              button
              className={clsx(classes.item, true)}
            >
              <ListItemIcon className={classes.itemIcon}><AccountBox /></ListItemIcon>
              <ListItemText classes={{ primary: classes.itemPrimary }}>
                {t('Account')}
            </ListItemText>
            </ListItem>
          </li>
        }
        <li>
          <ListItem
            component="a" href={`${shrimp}?iss=${fhirClient?.state?.serverUrl}`} target="_blank"
            button
            className={clsx(classes.item, true)}
          >
            <ListItemIcon className={classes.itemIcon}><Visibility /></ListItemIcon>
            <ListItemText classes={{ primary: classes.itemPrimary }}>
              Shrimp
            </ListItemText>
          </ListItem>
        </li>
        <ListItem
          component="a" href={`${snapper}?iss=${fhirClient?.state?.serverUrl}`} target="_blank"
          button
          className={clsx(classes.item, true)}
        >
          <ListItemIcon className={classes.itemIcon}><Edit /></ListItemIcon>
          <ListItemText classes={{ primary: classes.itemPrimary }}>
            Snapper
            </ListItemText>
        </ListItem>
        <li><Divider className={classes.divider} /></li>
      </List>
    </Drawer>
  );
}

export default withTranslation()(withStyles(styles)(Navigator));
