
import React from "react";
import Client from 'fhirclient/lib/Client';


export interface IOntoClient {
    canUpload: boolean;
    canSynd: boolean;
    hasDiff: boolean;

    userinfo_endpoint?: string;
    account_endpoint?: string;

    user: string;

    clearCache(): void;

    validate(url: string): Promise<Response>;

    getSyndStatus(resourceType:string,id:string):Promise<boolean>;
    setSyndStatus(resourceType:string,id:string,status:boolean):Promise<Response>;
    redoPreload():Promise<Response>;
    getUpstream():Promise<Response>;
    getJobs():Promise<Response>;
    fetchById(entryId:String):Promise<Response>;
    indexCodeSystem(codeSystem: String, version: String): Promise<Response>;
}

interface SmartContextProps {
    client?: Client;
    onto?: IOntoClient;
    error?: any;
    url?: string;
    refresh: () => Promise<any>;
}

const context:SmartContextProps = {
  refresh: () => {return Promise.resolve()},
};

export default React.createContext(context);
