import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ResourcesCard } from './ResourcesCard';
import { Tabs, Tab } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      // maxWidth: 936,
      margin: 'auto',
      overflow: 'hidden',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 916px',
    },
  });

type ContentParams = {
  id: string
};

const res = [
  { type: 'CodeSystem', av: 'CS' },
  { type: 'ValueSet', av: 'VS' },
  { type: 'ConceptMap', av: 'CM' },
  { type: 'NamingSystem', av: 'NS' },
  { type: 'StructureDefinition', av: 'SD' },
  { type: 'Bundle', av: 'B' },
];

function resIndex(id:string) {
  const idx = res.findIndex(e => e.type === id);
  return idx > 0 ? idx : 0;
}

export interface ContentProps extends WithStyles<typeof styles> { }

function Content(props: ContentProps) {
  const { id } = useParams<ContentParams>();
  const history = useHistory();
  const search = useLocation().search;
  const [tab, setTab] = React.useState(resIndex(id));

  React.useEffect(() => {
    history.replace({pathname: res[tab].type, search: search})
  }, [tab, history, search]);

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="default">
        <Tabs value={tab} onChange={handleChange}>
          {
            res.map((e, i) => {
              return (
                <Tab key={i} label={e.type} {...a11yProps(i)} />
              )
            })
          }
        </Tabs>
      </AppBar>
      {res.map((e, i) => (
        <TabPanel key={i} value={tab} index={i}>
          <ResourcesCard avatar={e.av} type={e.type} />
        </TabPanel>
      ))}
    </React.Fragment>
  );
}

export default withStyles(styles)(Content);
