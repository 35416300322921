import React from "react";
import FHIR from "fhirclient";
import SmartContext, { IOntoClient } from "./SmartContext";
import Client from "fhirclient/lib/Client";
import fetch from 'cross-fetch';
import { Redirect } from "react-router-dom";
import { fetchConformanceStatement } from "fhirclient/lib/lib";


class OntoClient implements IOntoClient {

  access_token?: string;
  base: string;

  userinfo_endpoint?: string;
  account_endpoint?: string;

  user: string = 'unknown';
  canUpload: boolean;
  canSynd: boolean;
  hasDiff: boolean;

  _caches: any = {
    default: {},
  };
  _client: Client;

  constructor(client: Client, info: {userinfo: any, account: any}) {
    this._client = client;
    this.access_token = client.state.tokenResponse?.access_token;
    this.base = new URL('./', client.state.serverUrl).href; // strips "[path]/fhir" back to "[path]"
    this.hasDiff = true;
    fetchConformanceStatement(client.state.serverUrl).then(stmt => {
      const rest: any = stmt.rest[0];
      this.hasDiff = rest.operation?.find((op:any) => 'diff' === op.name) ? true : false;

      const KEY = 'oc_knownEndpoints';
      const knownEndpoints = JSON.parse(localStorage.getItem(KEY) || '{}');
      knownEndpoints[client.state.serverUrl] = true;
      localStorage.setItem(KEY, JSON.stringify(knownEndpoints));
      sessionStorage.setItem('oc_lastEndpoint', client.state.serverUrl);
    });

    if (info.userinfo) {
      const self = this;
      fetch(info.userinfo, {headers:{'Authorization': 'Bearer ' + this.access_token}})
        .then(function (result) {
          const data:any = result.json;
          var name = (data && (data.name || data.user_name || data.username)) || 'unknown';
          self.user = name;
          return name;
        }, function (error) {
          console.error('Failed to get user info', error);
        });
    }

    if (this.access_token) {
      const jwt = JSON.parse(atob(this.access_token.split('.')[1]));

      if (jwt.name) {
        this.user = jwt.name;
      } else if (jwt.preferred_username) {
        this.user = jwt.preferred_username;
      } else if (jwt.username) {
        this.user = jwt.username;
      }

      const authorities = new Set<String>((jwt.authorities || [])
        .map((a: String) => a.startsWith(client.state.serverUrl) ? a.substring(client.state.serverUrl.length) : a));

      this.canUpload = authorities.has('FHIR_CS_X_UE');
      this.canSynd = authorities.has('SYND_WRITE');
    } else {
      this.canUpload = true;
      this.canSynd = true;
    }

    this.userinfo_endpoint = info.userinfo;
    this.account_endpoint = info.account;
  }

  clearCache(): void {
    Object.keys(this._caches).forEach(key => {
      delete this._caches[key];
    })
    this._caches.default = {};
  }

  validate(url: string): Promise<Response> {
    let cache = this._caches.default;
    if (this.access_token) {
      if (!this._caches[this.access_token]) {
        this._caches[this.access_token] = {};
      }
      cache = this._caches[this.access_token];
    }
    const result = cache[url];
    if (result) {
      return Promise.resolve(result);
    }

    return this._client.request(url + '/$validate')
    .then(response => {
      // Should really process the ETag here, but it is not exposed to us
      cache[url] = response;
      return response;
    })
  }

  request(url: string, options?: RequestInit): Promise<Response> {
    const absUrl: string = new URL(url, this.base).href;
    const headers: any = {
      'Accept': 'application/json',
      ...options?.headers,
    };
    if (this.access_token) {
      headers['Authorization'] = 'Bearer ' + this.access_token;
    }
    // headers['Accept'] = 'application/json';

    return fetch(absUrl, {
      mode: 'cors',
      body: '',
      ...options,
      headers: headers
    })
  }

  getSyndStatus(resourceType: string, id: string): Promise<boolean> {
    return this.request(`synd/getSyndicationStatus?resourceType=${resourceType}&id=${id}`)
      .then(response => { return response.ok ? response.json() : {} as any })
      .then(parameters => (parameters.parameter || []).find((p: any) => p.name === 'isSyndicated')?.valueBoolean as boolean)
  };
  setSyndStatus(resourceType: string, id: string, status: boolean): Promise<Response> {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: '',
    };
    return this.request(`synd/setSyndicationStatus?resourceType=${resourceType}&id=${id}&syndicate=${status}`, options)
  };

  redoPreload(): Promise<Response> {
    return this.request('synd/redoPreload', { method: 'POST' })
  }

  getJobs(): Promise<Response> {
    return this.request('api/jobs', { headers: { 'Accept': 'application/json' } })
  }

  getUpstream(): Promise<Response> {
    return this.request('api/upstream.xml', { headers: { 'Accept': 'application/atom+xml' } })
  }
  fetchById(entryId: string): Promise<Response> {
    return this.request(`synd/fetchSyndicatedContentEntry?entryId=${entryId}`, { method: 'POST' })
  }
  indexCodeSystem(codeSystem: string, version: string): Promise<Response> {
    return this.request(`api/indexCodeSystem?codeSystemId=${encodeURIComponent(codeSystem)}&codeSystemVersion=${encodeURIComponent(version)}&validate=false`, { method: 'POST' });
  }
}

const getAccountDetails = function (client: Client) {
  const fhirUrl = client.state.serverUrl;

  return fetch(fhirUrl + '/.well-known/smart-configuration', {headers: {'Accept': 'application/json'}})
    .then(response => response.json())
    .then(smartConfig => {
      // this.oauth.end_session_endpoint = smartConfig.end_session_endpoint;
      const ui = smartConfig.userinfo_endpoint;

      if (smartConfig.issuer) {
        return fetch(smartConfig.issuer, {headers: {'Accept': 'application/json'}})
          .then(response => response.json())
          .then(function (issuerResponse) {
            const ac = issuerResponse['account-service'];
            return {
              userinfo: ui,
              account: ac,
            }
          });
      }

      return {
        userinfo: ui,
        account: undefined,
      }
    });
}

export default class SmartProvider extends React.Component {
  state: {
    client?: Client;
    onto?: IOntoClient;
    error: any;
  };
  mounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      client: undefined,
      onto: undefined,
      error: null,
    };
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false
  }

  async refresh(serverUrl?:string) {
    // console.log('refresh')

    if (serverUrl) {
      // 401 or 403 - need to re-auth
      console.log('re-launch', serverUrl)
      window.location.href = `${process.env.PUBLIC_URL}/launch?iss=${serverUrl}`
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/login`
      // throw new Error('No server URL')
      return <Redirect to={{pathname:"/error", search:"?message=" + encodeURIComponent(this.state.error)}} />
    }
  }

  render() {
    if (this.state.error) {
      const serverUrl = this.state?.client?.state?.serverUrl;

      console.log('**** SmartProvider ERROR', this.state.error, serverUrl)
      if ((''+this.state.error).indexOf("No 'state' parameter found") < 0) {
        this.refresh()
      }
      this.refresh(serverUrl)
      // return <Redirect to={"/error?message=" + encodeURIComponent(this.state.error)} />
    }

    return (
      <SmartContext.Provider
        value={{
          client: this.state.client,
          onto: this.state.onto,
          error: this.state.error,
          refresh: () => {
            return this.refresh(this.state.client?.state?.serverUrl)
          },
          // url: this.state.client?.state.serverUrl,
        }}
      >
        <SmartContext.Consumer>
          {({ client }) => {
            if (this.state.error) {
              console.log('FHIR error', this.state.error);
              return null;
            }
            if (!client) {
              // console.log('GET CLIENT', client, this.mounted);
              FHIR.oauth2.ready()
                .then(client => {
                  // console.log('SET STATE', client, this.mounted);
                  if (this.mounted) {
                    getAccountDetails(client)
                    .then(info => {
                      this.setState({ client: client, onto: new OntoClient(client, info) })
                    })
                  }
                })
                .catch(error => {
                  if (this.mounted) {
                    this.setState({ error })
                    // console.log('ERROR', error)
                    throw error
                  }
                });
              return null;
            }
            return this.props.children;
          }}
        </SmartContext.Consumer>
      </SmartContext.Provider>
    );
  }
}
