
import React from 'react';
import { R4 } from '@ahryman40k/ts-fhir-types';
import ResourceTree from './ResourceTree';
import SmartContext from './SmartContext';
import { CircularProgress } from '@material-ui/core';

interface ResourceDetailsProps {
    url?: string;
    resource?: R4.IResource;
}

export const ResourceDetails = (props: ResourceDetailsProps) => {
    const { url, resource } = props;
    const [loading, setLoading] = React.useState(false)
    const [details, setDetails] = React.useState(resource)
    const smart = React.useContext(SmartContext);
    const fhirClient = smart.client

    React.useEffect(() => {
        if (url) {
            fhirClient?.request(url)
                .then(res => {
                    setDetails(res)
                })
                .catch(error => {
                    console.log('error', error)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [fhirClient, url]);

    return loading ? (<CircularProgress />) : (<ResourceTree resource={details} />)
}
