import React from 'react';
import SmartContext from './SmartContext';
import { Paper } from '@material-ui/core';
import MaterialTable, { Column } from '@material-table/core';
import CodeableConcept from './CodeableConcept';
import ReactJson from 'react-json-view';

export interface ValidationProps {
    id: string;
    title: string;
    resource: string;
}

export default function Validation(props: ValidationProps) {
    const { resource } = props;
    const [err, setErr] = React.useState<any>();
    const [result, setResult] = React.useState<any>();
    const smart = React.useContext(SmartContext);
    const fhirClient = smart.onto

    // console.log('VALIDATION', open, resource, fhirClient)

    React.useEffect(() => {
        if (resource) {
            let mounted = true
            console.log('VALIDATE', resource, fhirClient)
            setErr(false)
            setResult(undefined)
            fhirClient?.validate(resource)
                .then(response => {
                    console.log('RESULT', response)
                    if (mounted) {
                        // response.issue[0].details = {
                        //     text: 'some user-entered text',
                        //     coding: [{
                        //         system: 'http://example.com/CS/foo',
                        //         code: 'ABC',
                        //         display: 'The ABC code'
                        //     }]
                        // }
                        setResult(response)
                    }
                })
                .catch(reason => { console.log('ERR', reason); setErr(reason.body) })
            return () => { mounted = false }
        }
    }, [fhirClient, resource])

    return (err ?
            <ReactJson
                src={err}
                name={null}
                enableClipboard={false}
                displayDataTypes={false}
            /> :
            <MaterialTable
                isLoading={result === undefined}
                components={{
                    Container: props => <Paper {...props} square elevation={0} />
                }}
                columns={[
                    { title: 'Severity', field: 'severity', },
                    { title: 'Code', field: 'code', },
                    { title: 'Details', field: 'details', render: rowData => <CodeableConcept cc={rowData.details} /> },
                    { title: 'Diagnostics', field: 'diagnostics', width: '50%', } as Column<any>,
                    { title: 'Location', field: 'location', },
                    { title: 'Expression', field: 'expression', },
                ]}
                options={{
                    grouping: true,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10,25,100,500],
                    emptyRowsWhenPaging: false,
                    padding: 'dense',
                    search: false,
                    showTitle: false,
                    toolbar: false,
                }}
                data={result?.issue}
            />
    );
}
