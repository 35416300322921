import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  createTheme,
  createStyles,
  ThemeProvider,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Navigator from './Navigator';
import Body from './Body';
import Copyright from './Copyright';
import Error from './Error';
import Login from './Login';
import Launcher from './Launcher';
import SmartProvider from './SmartProvider';

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#007DB8',
      dark: '#006db3',
    },
    secondary: {
      main: '#EB0000',
    },
    text: {
      secondary: "#6A6C6D",
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: false,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.primary.main,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 192;
const background = '#eaeff1';

const styles = createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(1, 1),
    background: background,
  },
  footer: {
    padding: theme.spacing(1),
    background: background,
  },
});

export interface PaperbaseProps extends WithStyles<typeof styles> { }

function Paperbase(props: PaperbaseProps) {
  const { classes } = props;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Switch>
          <Route key="launch" path="/launch">
            <div className={classes.app}>
              <Launcher />
            </div>
          </Route>
          <Route key="error" path="/error" children={<Error />} />
          <Route key="login" path="/login">
            <div className={classes.app}>
              <Login />
            </div>
          </Route>
          <Route key="home" path="/" exact><Redirect to="/about" /></Route>
          <Route key="other">
            <SmartProvider>
              <nav className={classes.drawer}>
                <Navigator PaperProps={{ style: { width: drawerWidth } }} />
              </nav>
              <div className={classes.app}>
                <Body />
                <footer className={classes.footer}>
                  <Copyright />
                </footer>
              </div>
            </SmartProvider>
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Paperbase);
