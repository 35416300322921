import React from 'react';
import SmartContext from './SmartContext';
import logo from './ontoserver.png';

function Branding() {
    const smart = React.useContext(SmartContext);
    const fhirClient = smart.client;
    const [endpoint, setEndpoint] = React.useState<string|undefined>()
    const [missing, setMissing] = React.useState(false)

    React.useEffect(() => {
        setEndpoint(fhirClient?.state?.serverUrl)
    }, [fhirClient])

    return endpoint && !missing ? (
    <img style={{maxWidth: '100px'}} alt='Endpoint Logo' role="presentation" src={`${endpoint}/.well-known/logo.png`} onError={() => {setMissing(true)}} />
    ) : (<img style={{maxWidth: '100px'}} alt='Ontoserver Logo' role="presentation" src={logo} />);
}

export default Branding;
