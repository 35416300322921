
import React from 'react';
import DOMPurify from 'dompurify';
import SmartContext from './SmartContext';
import { CircularProgress, CardContent } from '@material-ui/core';

interface NarrativeProps {
    type: string;
    id: string;
    noContent?: () => void;
    onError?: (error: any) => void;
}

export const Narrative = (props: NarrativeProps) => {
    const { type, id, onError = (err: any) => { console.log('Error', err) }, noContent = () => { } } = props;
    const fhirClient = React.useContext(SmartContext).client

    const [loading, setLoading] = React.useState(false)
    const [narrative, setNarrative] = React.useState<string>()

    React.useEffect(() => {
        let mounted = true
        setLoading(true)
        const url = `/${type}/${id}?_elements=text&_format=json`;
        // console.log('CONTEXT', url)
        fhirClient?.request(url)
            .then((res: any) => {
                if (mounted) {
                    setLoading(false)
                    const text = (res?.text?.div || 'null') as string;
                    if ('null' === text) {
                        setNarrative(`<strong>No narrative for ${id}</strong>`)
                        noContent()
                    } else {
                        const resolved = text
                            .replace(/(src|href)="([^/"]*)"/g, '$1="https://hl7.org/fhir/$2"')
                        const formatted = resolved
                            .replace(/<table/, '<table style="width:100%"')
                            .replace(/width:\s+100px/, 'min-width: 100px')
                        setNarrative(formatted)
                    }
                }
            }, (error) => {
                if (mounted) {
                    setLoading(false)
                    onError(error)
                }
            })
        return () => { mounted = false }
    },
        // eslint-disable-next-line
        [fhirClient, type, id])


    return (
        <React.Fragment>
            {loading ? <CircularProgress /> :
                <CardContent dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(narrative || '') }} />
            }
        </React.Fragment>
    )
}
