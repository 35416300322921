import React from "react"
import SmartContext from "./SmartContext"
import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, Card, CardContent, makeStyles, CardHeader } from "@material-ui/core"
import ResourceTree from "./ResourceTree";
import DOMPurify from "dompurify";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    // maxWidth: 800,
    textAlign: 'left',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const NOT_SUPPORTED: R4.ITerminologyCapabilities = {
  resourceType: 'TerminologyCapabilities',
  title: 'TerminologyCapabilities not supported by server',
}

function TerminologyCapabilities(props: any) {
  const fhirClient = React.useContext(SmartContext).client
  const [loading, setLoading] = React.useState(false)
  const [details, setDetails] = React.useState<R4.ITerminologyCapabilities>({
    resourceType: 'TerminologyCapabilities',
    title: 'Loading TerminologyCapabilities...',
  })

  const classes = useStyles();

  React.useEffect(() => {
    fhirClient?.request('/metadata?mode=terminology')
      .then((cap: any) => {
        return 'TerminologyCapabilities' === cap.resourceType ? cap as R4.ITerminologyCapabilities : NOT_SUPPORTED
      })
      .then((cap: R4.ITerminologyCapabilities) => {
        setDetails(cap)
        setLoading(false)
      })
      .catch((reason) => {
        setDetails({
          resourceType: 'TerminologyCapabilities',
          title: 'Failed to load TerminologyCapabilities: ' + reason,
        })
        setLoading(false)
      })
  }, [fhirClient])

  return (
    <React.Fragment>
      <Card className={classes.root} square >
        <CardHeader title={details?.title || details?.name} />
        {loading ? <CircularProgress /> :
          <CardContent>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details?.text?.div || '') }} />
            {/* <ul>
                            <li>Text Filter: {details?.expansion?.textFilter}</li>
                            <li>Hierarchical: {'' + details?.expansion?.hierarchical}</li>
                            <li>Paging: {'' + details?.expansion?.paging}</li>
                        </ul> */}
            <ResourceTree resource={{ 'Details': details }} />
          </CardContent>
        }
      </Card>
    </React.Fragment>
  )
}

export default TerminologyCapabilities
