
import React from 'react';
import { useTranslation } from 'react-i18next';

const langs = ['en'
  , 'es'
  , 'fr'
  , 'id'
  , 'se'
];

function Languages() {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
        {
          langs.map((lng) => (
            <option key={lng}>
              {lng}
            </option>
          ))
        }
      </select>
    </React.Fragment>
  )
}

export default Languages;
