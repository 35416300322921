import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
    createTheme,
    createStyles,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';
import Header from './Header';
import About from './About';
import Syndication from './Syndication';
import { Upload } from './Upload';
import Endpoint from './Endpoint';
import Content from './Content';
import Jobs from './Jobs';


let theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const drawerWidth = 192;
const background = '#eaeff1';

const styles = createStyles({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(1, 1),
        background: background,
    },
    footer: {
        padding: theme.spacing(1),
        background: background,
    },
});

export interface BodyProps extends WithStyles<typeof styles> { }

function Body(props: BodyProps) {
    const { classes } = props;

    return (
        <React.Fragment>
            <Header>
                <Endpoint />
            </Header>
            <main className={classes.main}>
                <Switch>
                    <Route key="about" path="/about" component={About} />
                    <Route key="upload-sct" path="/upload-sct" component={Upload} />
                    <Route key="upstream" path="/upstream" children={<Syndication feed="/api/upstream.xml" fetch={true} />} />
                    <Route key="syndication" path="/syndication" children={<Syndication feed="/synd/syndication.xml" fetch={false} />} />
                    <Route key="jobs" path="/jobs" children={<Jobs fetch={false} />} />

                    {/* <Route key="applications" path="/applications" children={<Applications />} />
                    <Route key="shrimp" path="/shrimp/:launch" component={Shrimp} />
                    <Route key="snapper" path="/snapper/:launch" component={Snapper} /> */}

                    {/* <Route path="/category" component={Category} /> */}
                    {/* <Route path="/products" component={Products} /> */}
                    <Redirect from="/resource" to="/resource/default" exact={true} />
                    <Route key="home" path="/resource/:id" children={<Content />} />
                </Switch>
            </main>
        </React.Fragment>
    );
}

export default withStyles(styles)(Body);
