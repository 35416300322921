import React from "react";
import FHIR from 'fhirclient';
import fetch from 'cross-fetch';
import { createTheme, createStyles, withStyles, CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";

let theme = createTheme();

const background = '#eaeff1';

const styles = createStyles({
  main: {
    flex: 1,
    padding: theme.spacing(10, 10),
    background: background,
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

declare var ENV: {
  ONTO: { [key: string]: any }
}

const Launcher = function () {

  const query = useQuery();
  const iss = query.get('iss') || '';
  const url = iss.endsWith('/') ? iss.slice(0, -1) : iss;   // strip trailing /

  const error = query.get('error');
  const description = query.get('error_description');

  const [cId, setCId] = React.useState(query.get('clientId') || ENV.ONTO.clientIds[url] || 'onto-ui')
  const [reg, setReg] = React.useState(true)

  const redirectUrl = new URL('about', window.location.href).href;

  if (!error) {

    // Currently cId will always have a default value ('onto-ui'), so this code will never trigger
    if (!cId && reg) {
      console.log('ATTEMPT REGISTRATION')
      setReg(false)

      const base = new URL('.', window.location.href).href
      fetch(base + '/metadata?_format=json')
        .then(response => {
          response.json()
            .then(cs => {
              const register: string =
                cs.rest?.find((s: any) => s?.security?.extension?.url === 'http://fhir-registry.smarthealthit.org/StructureDefinition/oauth-uris')
                  .security.extension.extension?.find((e: any) => e.name === 'register')
                  .valueUri;

              if (register) {
                fetch(register, {
                  body: JSON.stringify({
                    "redirect_uris": [base],
                    "client_name": "Ontoserver UI",
                    "client_uri": base,
                    "logo_uri": "https://ontoserver.csiro.au/ui/ontoserver.png",
                    "software_id": "au.csiro.ontoserver.ui",
                    "software_version": process.env.REACT_APP_VERSION,
                    "grant_types": ["authorization_code"],
                    "response_types": ["code"],
                    "token_endpoint_auth_method": "none",
                    "mode": 2   // Grahame special
                  })
                }).then(response2 => {
                  response2.json().then(registration => {
                    setCId(registration.client_id)
                  })
                })
              }
            })
            .catch(reason => {
              console.log('Metadata failure', reason)
            })
        })
    }

    console.log('AUTH WITH', url, cId, redirectUrl)
  }

  if (error) {
    return (<div><b>OAuth2 error:</b> <samp>{description}</samp></div>)
  }
  if (!url) {
    return (<div>No launch endpoint specified?</div>)
  }

  window.history.pushState(null, '', '.');
  setTimeout(() =>
    FHIR.oauth2.authorize({
      fhirServiceUrl: iss,
      redirectUri: redirectUrl,
      clientId: cId,
    }).then(error => {
      console.log('AUTH ERROR', error)
      return error
    }).catch(reason => {
      console.log('AUTH CAUGHT', reason)
      throw reason
    }), 200
  );

  return (
    <React.Fragment>
      <CircularProgress />
    </React.Fragment>
  );
}

export default withStyles(styles)(Launcher)
