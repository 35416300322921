import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import Changelog from './Changelog';
import Languages from './Languages';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    block: {
      display: 'block',
    },
    urlInput: {
      fontSize: theme.typography.fontSize,
    },
  });

interface HeaderProps extends WithTranslation, WithStyles<typeof styles> {
  classes: any;
  children?: React.ReactNode;
}

function Header(props: HeaderProps) {
  const { classes, t } = props;
  const [changelog, setChangelog] = React.useState(false)

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Typography color="inherit" variant="h5" component="h1">
                {t('dashboardTitle')} ({process.env.REACT_APP_VERSION})
              </Typography>
            </Grid>
            <Grid item xs />
            { props.children }
            {/* <Grid item>
              <Link href="https://ontoserver.csiro.au/docs/" color="inherit" variant="body2">
                {t('goToDocs')}
              </Link>
            </Grid> */}
            <Grid item>
              <Tooltip aria-hidden="false" title="{t('changelog')}" aria-label="Changelog">
                <IconButton color="inherit" onClick={() => setChangelog(true)}>
                  <Icon>newspaper</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              {/* <Tooltip aria-hidden="false" title="{t('alerts')}" aria-label="Alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip> */}
              <Languages />
            </Grid>
            {/* <Grid item>
              <Tooltip title="{t('help')}" aria-label="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
      </AppBar>
      <Changelog open={changelog} onClose={() => setChangelog(false)} />
    </React.Fragment>
  );
}

export default withTranslation()(withStyles(styles)(Header));
