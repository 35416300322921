import React from 'react'
import { Button, Card, Snackbar, CircularProgress, makeStyles, Grid, Tooltip } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MaterialTable, { Action, Column } from '@material-table/core';
import SmartContext from './SmartContext';
import { ResourceExists } from './ResourceExists';
import { GetApp, CheckCircle, Refresh } from '@material-ui/icons';
import SnackOutcome from './SnackOutcome';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Entry {
  id: string;
  title: string;
  category: string;
  scheme?: string;
  item: string;
  url: string;
  version: string;
  href?: string;
  source: {
    title?: string;
  }
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getElement = (node: Element | undefined | null, name: string, index: number = 0) => {
  return node?.getElementsByTagName(name).item(index)
}
const destructure = (node: Element | undefined | null, names: string[], ns?: string) => {
  const result: any = {}
  names.forEach(n => {
    result[n] = ns ? node?.getElementsByTagNameNS(ns, n).item(0) : node?.getElementsByTagName(n).item(0)
  })
  return result;
}

const actionable = (item: Entry) => {
  switch (item.category) {
    case "BINARY":
    case "FHIR_Bundle":
    case "FHIR_CodeSystem":
    case "FHIR_ConceptMap":
    case "FHIR_NamingSystem":
    case "FHIR_StructureDefinition":
    case "FHIR_ValueSet":
    case "FHIR_Package":
      return true;
    case "SCT_RF2_FULL":
    case "SCT_RF2_SNAPSHOT":
    case "SCT_RF2_ALL":
        return true;
    case "AMT_CSV":
    case "SCT_RF2_DELTA":
      return false;
    default:
      console.log("UNKNOWN", item.category);
      return false;
  }
}

const wrappable = (v: string, p: string) => (v||'').split(p).map((seg, i) => (<span key={i}>{i ? p : ''}{seg}<wbr /></span>));

const useStyles = makeStyles({
    preload: {
      marginBottom: "8px",
    },
});

interface SyndicationProps extends WithTranslation {
  fetch: boolean;
  feed: string;
}

function Syndication(props: SyndicationProps) {
  const { fetch, t } = props;
  // const classes = useStyles();
  const smart = React.useContext(SmartContext)

  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState<Document>();
  const [alert, setAlert] = React.useState(false);
  const [warn, setWarn] = React.useState<string[]>([]);
  const [data, setData] = React.useState<Entry[]>([]);
  const [processing, setProcessing] = React.useState<any>({});
  const [outcome, setOutcome] = React.useState<any>({});

  const tt = (arg: string) => t(arg) || arg;

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert(false)
  };

  const handleWarnClose = (id: string, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setWarn(warn.filter(elt => elt !== id))
  };

  React.useEffect(() => {
    if (smart.client) {
      let mounted = true;
      (async () => {
        setLoading(true)
        smart.onto?.getUpstream()
          .then(response => {
            if (response.status === 401) {
              response.text().then(() => setWarn([t('SyndAuthRequired')]));
            }
            if (response.status > 400) {
              throw response;
            }
            return response;
          })
          .then(response => response.text())
          .then((text) => {
            if (mounted) {
              setState(new DOMParser().parseFromString(text, 'text/xml'))
              setLoading(false)
            }
          }, (error) => {
            console.log('Failed getting upstream feed', error);
            if (mounted) {
              setState(new Document())
              setLoading(false)
              setAlert(true)
            }
          })
      })();
      return () => { mounted = false }
    }
  }, [smart, t])

  React.useEffect(() => {
    const theData: Entry[] = []
    // const theSystems = {}
    const entries = state?.getElementsByTagName('entry')
    for (var i = 0; i < (entries?.length || 0); i++) {
      const entry = entries?.item(i)
      const { id, title, category, } =
        destructure(entry, ['id', 'title', 'category',])
      const { contentItemIdentifier: item, contentItemVersion: version, } =
        destructure(entry, ['contentItemIdentifier', 'contentItemVersion'], 'http://ns.electronichealth.net.au/ncts/syndication/asf/extensions/1.0.0')
      const term = category?.getAttribute('term')
      const scheme = category?.getAttribute('scheme')
      const link = getElement(entry, 'link')
      // const linkType = link?.getAttribute('type')

      if (!link?.getAttribute('href')) {
        return;
      }
      const href = new URL(link?.getAttribute('href') || '').hostname

      const srcTitle = getElement(getElement(entry, 'source'), 'title')?.textContent
      // console.log('LINK', i, category, term, entry, linkType, title?.textContent)
      // console.log('ITEM', item)
      const url = item?.textContent.startsWith('http://snomed.info/sct') ? 'http://snomed.info/sct' : item?.textContent

      // theSystems[item]
      theData.push({
        id: id?.textContent,
        title: title?.textContent,
        item: item?.textContent,
        url: url,
        version: version?.textContent || '',
        href: href,
        category: term,
        scheme: scheme,
        source: {
          title: srcTitle || '',
        }
      })
    }
    setWarn(theData.filter(entry => 'WARNING' === entry.category).map(entry => entry.id))
    setData(theData)
  }, [state])

  const preload: () => void = () => {
    smart.onto?.redoPreload()
      .then(response => {
        if (response.status === 401) {
          setWarn([t('GenericAuthRequired')]);
          setAlert(true);
        }
        return response.text()
      })
      .then(data => console.log('PRELOAD', data))
  };

  const handleGetResource: (event: any, data: Entry | Entry[]) => void = (event, rowData) => {
    console.log(rowData);
    if (Array.isArray(rowData)) {
      console.log('Unexpected array', rowData);
    } else {
      const id = rowData.id
      setProcessing({ ...processing, [id]: 1 })

      switch (rowData.category) {
        case "SCT_RF2_ALL":
        case "SCT_RF2_FULL":
        case "SCT_RF2_SNAPSHOT":
          const system = rowData.item.startsWith('http://snomed.info/sct') ? 'http://snomed.info/sct' : rowData.item;
          const version = rowData.version;
          console.log('Indexing', system, version)
          smart.onto?.indexCodeSystem(system, version)
            .then(response => {
              // const json = response.json()
              console.log('STATUS', response.status, response.statusText)
              setProcessing({ ...processing, [id]: response.ok ? 2 : 0 })
              if (!response.ok) {
                response.body?.getReader().read().then(data => {
                  console.log("BODY", data)
                })
              }
              return response.ok ? {
                success: `Indexed ${rowData.title}`
              } : {
                error: `Request failed (${response.status})`
              }
            })
            .then(json => {
              setOutcome({ ...outcome, [id]: json })
              console.log('INDEXED', json)
            })
            .catch(err => {
              console.log('INDEX ERROR', err)
            })
        break
        default:
          console.log('Fetching', id)
          smart.onto?.fetchById(id)
            .then(response => {
              const json = response.json()
              console.log('STATUS', json, response.status, response.statusText)
              setProcessing({ ...processing, [id]: response.ok ? 2 : 0 })
              if (!response.ok) {
              }
              return response.ok ? json : json
            })
            .then(json => {
              setOutcome({ ...outcome, [id]: json })
              console.log('FETCHED', json)
            })
            .catch(err => {
              console.log('FETCH ERROR', err)
            })
      }
    }
  };

  const actions = fetch ? [
    (rowData: Entry): Action<Entry> => ({
      disabled: 1 <= processing[rowData.id] || !actionable(rowData),
      icon: () => (<div>
        {processing[rowData.id] === 2 ? (<CheckCircle />) : (<GetApp />)}{processing[rowData.id] === 1 && (<CircularProgress style={{
          position: 'absolute', top: -6, left: -6, zIndex: 1,
        }} />)}
      </div>),
      tooltip: 'Load Resource',
      onClick: handleGetResource
    }),
  ] : [];

  const classes = useStyles();
  const canSynd = smart?.onto?.canSynd ? 'TRUE' : 'FALSE';

  return (
    <React.Fragment>
      {
        warn && data
          .filter(entry => 'WARNING' === entry.category)
          .map((entry: Entry, idx) => (
            <Snackbar key={idx} open={warn.includes(entry.id)} autoHideDuration={60000} onClose={(evt, rsn) => handleWarnClose(entry.id, rsn)}>
              <Alert key={idx} onClose={(evt) => handleWarnClose(entry.id)} severity="warning">
                {entry.title}
              </Alert>
            </Snackbar>
          ))
      }
      <Snackbar open={alert} autoHideDuration={60000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {warn[0] || t('SyndFetchFailure')}
        </Alert>
      </Snackbar>
      {
        Object.keys(outcome).map((id, i) => {
          return (<SnackOutcome key={i} outcome={outcome[id]} />)
        })
      }

      <Grid container spacing={6} alignItems="center">
        <Grid item xs={2}>
          <Tooltip title={tt('Redo preloading')} placement="right">
            <Button disabled={!canSynd} startIcon={<Refresh/>} className={classes.preload} variant="contained" color="primary" onClick={preload}>{t('Preload')}</Button>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Column type is missing 'width' field, hence the need for a cast */}
      <Card square>
        <MaterialTable
          isLoading={loading}
          title={loading ? tt('Retrieving feed') : tt('Available Resources')}
          data={data.filter(entry => 'WARNING' !== entry.category)}
          columns={[
            // { title: 'Id', field: 'id', },
            { width: 10, title: '', field: undefined, sorting: false, render: rowData => <ResourceExists category={rowData.category} url={rowData.url} version={rowData.version} /> } as Column<Entry>,
            { width: '30%', title: t('Title'), field: 'title', sorting: true, defaultSort: 'asc', } as Column<Entry>,
            // { title: 'Item', field: 'item', },
            { title: tt('URL'), field: 'url', },
            {
              width: '10%', title: t('Version'), field: 'version', render: rowData => {
                const v = (rowData.version ?? rowData) as string;
                return (<span>{wrappable(v, '/')}</span>)
              }
            } as Column<Entry>,
            { title: tt('Category'), field: 'category', defaultGroupOrder: 1, },
            { title: tt('Scheme'), field: 'scheme', },
            // // { title: t('Link'), field: 'href', },
            { width: '20%', title: tt('Source'), field: 'source.title', defaultGroupOrder: 0, } as Column<Entry>,
          ]}
          options={{
            grouping: true,
            maxColumnSort: 1,
            // TODO waiting on pull request in MaterialTable
            // groupTitle: (group: any) => (
            //     <Badge
            //         color='primary'
            //         badgeContent={group.groups.length + group.data.length}
            //         anchorOrigin={{
            //             vertical: 'top',
            //             horizontal: 'left',
            //         }}
            //         max={999}
            //     >
            //         <span style={{ width: '1em' }}>&nbsp;</span>
            //     </Badge>
            // ),
            padding: 'dense',
            pageSize: 10,
          }}
          actions={actions}
          localization={{
            pagination: {
              nextAriaLabel: tt('Next Page'),
              previousAriaLabel: tt('Previous Page'),
              firstAriaLabel: tt('First Page'),
              lastAriaLabel: tt('Last Page')
            },
          }}
        />
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(Syndication);
