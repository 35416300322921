
import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Icon, makeStyles } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ChangelogProps extends WithTranslation {
  open: boolean,
  onClose: () => void,
}

const useStyles = makeStyles({
  MuiDrawer: {
    backgroundColor: 'white'
  }
});

const Changelog = (props: ChangelogProps) => {
  const { open, onClose, t } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  }

  return (
    <Drawer open={open} anchor='top' onClose={handleClose} classes={{ paper: classes.MuiDrawer }}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon fontSize='large'>newspaper</Icon>
          <span>&nbsp;{t('Changelog')}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ul>
            <li>Enable indexing for SNOMED CT RF2 syndication entries.</li>
            <li>If available, show user name when logged in.</li>
          </ul>
          <ul>
            <li>Added support for resource upload.</li>
            <li>Improved launch/re-launch flow.</li>
          </ul>
          <ul>
            <li>Added support for <code>$diff</code> operation to compare resources.</li>
            <li>Added changelog.</li>
            <li>Added translation support.</li>
            <li>Support the IPS URI for SNOMED CT.</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small" color="primary" variant="contained">Close</Button>
      </DialogActions>
    </Drawer>
  )
}

export default withTranslation()(Changelog)
