
import React from 'react';
import SmartContext from './SmartContext';
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, ListItemIcon, ListItemText, createStyles, makeStyles } from '@material-ui/core';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AttachFile } from '@material-ui/icons';

interface ResourceUploadProps extends WithTranslation {
  type: string,
  open: boolean,
  onClose: () => void,
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      background: "#ddd",
    },
    button: {
      marginLeft: '8px',
      width: '164px',
    },
    buttonProgress: {
      position: 'relative',
      top: '8px',
      left: '-96px',
    },
  }),
);

const ResourceUpload = (props: ResourceUploadProps) => {
  const { type, open, onClose, t } = props;
  const classes = useStyles();

  const fhirClient = React.useContext(SmartContext).client
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState<string|undefined>()
  const [json, setJson] = React.useState<any>()
  const [name, setName] = React.useState('')

  const handleClose = () => {
    setJson(undefined);
    setMessage('');
    onClose();
  }

  const handleUpload = () => {
    setLoading(true)
    setMessage('');

    const id = json.id;
    const [method, url] = id ? ['PUT', `${type}/${id}`] : ['POST', type];

    fhirClient?.request({
      method: method,
      url: url,
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(json),
      headers: {
        'Content-Type': 'application/fhir+json'
      }
    }).then((result) => {
      setLoading(false)
      // setJson(undefined)
      setMessage(`${name} successfully uploaded.`)
      // console.log(result)
    }).catch((error) => {
      setMessage(`${error}`)
      setLoading(false)
      console.log(error)
    })
  }

  const handleFileChange = (event: any) => {
    setJson(undefined)

    const file = event.target.files[0];
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      if (result) {
        const res = JSON.parse(result);
        if (type === res.resourceType) {
          setJson(res);
          setName(res.id ? `${file.name} (${res.id})` : file.name);
          setMessage('')
        } else {
          setMessage(`${file.name} was not a ${type} resource - found: ${res.resourceType}`)
        }
      } else {
        setMessage(`No resource loaded for ${file.name}`)
      }
    };
    reader.onerror = (e) => {
      setLoading(false);
      console.log('ERROR', e);
      setMessage(`${e}`);
    }
    reader.readAsText(file, 'UTF-8');
  }
  const handleFileRemove = () => {
    setJson(undefined)
    setMessage('')
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <NoteAddIcon/>
          &nbsp;<span>{t('Upload Resource', {type})}</span>

        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('The resource to upload.')}
        </DialogContentText>
        <Grid container>
          <Grid container item xs={12}>
            <FormControl style={{display: json ? 'none' : 'block'}}>
              <input
                hidden
                accept=".json,text/json"
                id="contained-button-file"
                multiple
                type="file"
                name="jsonFile"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <ListItemText>
                  <ListItemIcon>
                    <Button
                      variant="outlined"
                      color={json ? 'primary' : 'secondary'}
                      component="span"><AttachFile /> {t('SelectJSON', {type})}
                    </Button>
                  </ListItemIcon>
                </ListItemText>
              </label>
            </FormControl>
          </Grid>
          <Grid item xs={8} title="JSON files">
              <Box display="flex">
                {
                  json && <Chip key={'idx'} onDelete={handleFileRemove} label={name} color="primary" />
                }
              </Box>
              <Box display="block" color={'darkred'}>
                <p>{message}</p>
              </Box>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        <Button onClick={handleUpload} size="small" color="primary" variant="contained" disabled={!json || loading}>Upload</Button>
        <Button onClick={handleClose} size="small" color="secondary" variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(ResourceUpload)
