import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withTranslation, WithTranslation } from 'react-i18next';

function Copyright(props: WithTranslation) {
    const { t } = props;
  
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        <Link color="inherit" href="https://ontoserver.csiro.au/">
          Ontoserver
        </Link>
        <span> - {t('Copyright')} © {new Date().getFullYear()} CSIRO.</span>
      </Typography>
    );
  }
  
  export default withTranslation()(Copyright);
