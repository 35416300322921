import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://83660f07be514b8eac6d0eafb15c1a92@o144408.ingest.sentry.io/5426455",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    autoSessionTracking: false, // Disable session tracking
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.Suspense fallback="loading">
    <App />
  </React.Suspense>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
