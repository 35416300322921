import React from "react"
import CapabilityStatement from "./CapabilityStatement"
import TerminologyCapabilities from "./TerminologyCapabilities"
import { AppBar, Tabs, Tab } from "@material-ui/core"

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    { children }
                </React.Fragment>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const About = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <AppBar position="static" color="default" >
                <Tabs value={value} onChange={handleChange} aria-label="endpoint statement items">
                    <Tab label="Capability Statement" {...a11yProps(0)} />
                    <Tab label="Terminology Capabilities" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <CapabilityStatement />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TerminologyCapabilities />
            </TabPanel>
        </React.Fragment>
    )
}

export default About

