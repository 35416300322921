import React from 'react';
import { Box } from '@material-ui/core';

export interface TreeRowProps {
    label: string;
    index?: number;
    val: any;
}

const TreeRow = (props: TreeRowProps) => {
    const { label, index, val, } = props;
    const obj = 'object' === typeof val;
    const suffix = index !== undefined ? ('[' + index + ']') : '';
    return (
        <div style={{ display: 'flex', }}>
            <Box component="span" textAlign='left' mx={1} minWidth='10em'>
                {label}{suffix}
            </Box>
            <Box component="span">{!obj && val + ''}</Box>
        </div>
    );
};

export default TreeRow;
