import React from 'react';
import FHIR from 'fhirclient';
import Grid from '@material-ui/core/Grid';
import { createTheme, createStyles, Theme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, Button } from '@material-ui/core';
import SmartContext from './SmartContext';
import { Alert } from '@material-ui/lab';
import { withTranslation, WithTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    secondary: {
      main: "#61dafb"
      // main: "#005D9D"
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  }
});

const styles = (theme: Theme) =>
  createStyles({
    block: {
      display: 'block',
    },
    urlInput: {
      fontSize: theme.typography.fontSize,
    },
  });

const authenticate = (url: string, clientId: string) => {
  const redirectUrl = new URL('about', window.location.href).href;
  setTimeout(() =>
    FHIR.oauth2.authorize({
      iss: url,
      redirectUri: redirectUrl,
      clientId: clientId,
      scope: "openid profile",
    }).then(error => {
      console.log('AUTH ERROR', error)
      return error
    }).catch(reason => {
      console.log('AUTH CAUGHT', reason)
      throw reason
    }), 200
  );
}
const anonymous = (url: string, clientId: string) => {
  const redirectUrl = new URL('about', window.location.href).href;
  window.history.pushState(null, '', '.');
  setTimeout(() =>
    FHIR.oauth2.authorize({
      fhirServiceUrl: url,
      redirectUri: redirectUrl,
      clientId: clientId,
    }).then(error => {
      console.log('AUTH ERROR', error)
      return error
    }).catch(reason => {
      console.log('AUTH CAUGHT', reason)
      throw reason
    }), 200
  );
}

function Endpoint(props: WithTranslation) {
  const { t } = props
  const smart = React.useContext(SmartContext);
  // console.log('Endpoint', smart.error)
  const fhirClient = smart.client
  const [url, setUrl] = React.useState<string | undefined>()
  const [clientId, setClientId] = React.useState<string>('onto-ui')
  const [release, setRelease] = React.useState<string>();
  const [anon, setAnon] = React.useState(true);

  React.useEffect(() => {
    setAnon(!fhirClient?.getState('tokenResponse.token_type'));
    setClientId(fhirClient?.getState('clientId'));
    setUrl(fhirClient?.state?.serverUrl);
    let mounted = true
    fhirClient?.getFhirVersion()
      .then(version => {
        if (mounted) {
          setRelease(version)
        }
      })
    return () => { mounted = false }
  }, [fhirClient])

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Snackbar open={smart.error} autoHideDuration={60000}>
          <Alert severity="error">
            {t('Error')}: {smart.error?.text}
          </Alert>
        </Snackbar>
        <Grid item xs={6}>
          <Typography align="left">{url} {release ? `(${release})` : ''}</Typography>
        </Grid>
        <Grid item xs={1}>
          {!anon && <Typography>{smart.onto?.user}</Typography>}
        </Grid>
        <Grid item xs={1}>
          {url && (anon
            ? (<Button onClick={() => authenticate(url, clientId)} variant="contained" size="small" color="secondary" disableElevation>{t('Login')}</Button>)
            : (<Button onClick={() => anonymous(url, clientId)} variant="contained" size="small" color="secondary" disableElevation>{t('Logout')}</Button>)
          )
          }
        </Grid>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default withTranslation()(withStyles(styles)(Endpoint));
