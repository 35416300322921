
import React from 'react'
import { makeStyles } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeRow from './TreeRow';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        background: '#FAFAFA',
        marginLeft: '2em',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'left',
    },
});

const transformResource = (r: any, path: string) => {
    if ('object' === typeof r) {
        return Object.entries(r).flatMap(([key, val], jdx) => {
            if (Array.isArray(val)) {
                return val.map((elt, idx) => {
                    const nodeId = path + '.' + key + '_' + idx
                    return (
                        <TreeItem key={nodeId} nodeId={nodeId} label={<TreeRow label={key} index={idx} val={elt} />}>
                            {transformResource(elt, nodeId)}
                        </TreeItem>
                    )
                })
            } else {
                const nodeId = path + '.' + key
                return [
                    <TreeItem key={nodeId} nodeId={nodeId} label={<TreeRow label={key} val={val} />}>
                        {transformResource(val, nodeId)}
                    </TreeItem>
                ]
            }
        })
    } else {
        return []
    }
}

export interface ResourceTreeProps {
    resource: any;
}

const ResourceTree = (props: ResourceTreeProps) => {
    const { resource } = props;
    const classes = useStyles();
    const [treeData, setTreeData] = React.useState<any[]>([]);

    React.useEffect(() => {
        setTreeData(transformResource(resource, 'root') || []);
    }, [resource])

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {treeData}
        </TreeView>
    )
}

export default ResourceTree;
